import React, { useRef, useState } from "react";
import {
  Barcode,
  Br,
  Cut,
  Image,
  Line,
  Printer,
  QRCode,
  Row,
  Text,
  render,
} from "react-thermal-printer";
import ReactToPrint from "react-to-print";
// import { Barcode as Barcode2 } from "react-barcode";
// import { TscTtpPrinter } from "tsc-ttp-react-printer";

const BarcodePrint = ({ barcodeValue }) => {
  const printRef = useRef(null);
  const printBasicRef = useRef(null);
  const [width, setWidth] = useState(34);
  const [height, setHeight] = useState(64);
  const [count, setCount] = useState(1);
  var pageStyle = ` 
  @page {
      size:${width}mm ${height}mm;
      margin: 0 !important;
      padding: 0 !important;
      overflow: hidden;
    };


    @media all {
      @page {
            size:${width}mm ${height}mm;
                margin: 0 !important;
    padding: 0 !important;
      }
        html, body {
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  }
      .pageBreak {
        display : none;
      }
    };

    @media print {



        html, body {
    height: 100vh; /* Use 100% here to support printing more than a single page*/
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  }


      @page {
            size:${width}mm ${height}mm;
      }


      .pageBreak {
            margin: 0 !important;
    padding: 0 !important;
        page-break-before: always;
      }


    };

    `;
  // React.useEffect(() => {
  //   if (printRef.current) {
  //     JsBarcode(printRef.current, barcodeValue);
  //   }
  // }, [barcodeValue]);

  return (
    <div className="">
      <div className="" style={{ padding: 10 }}>
        <div ref={printRef}>
          {Array.from({ length: count }, (_, index) => index + 1).map(
            (e, i) => (
              <div
                style={{
                  overflow: "hidden",
                }}
              >
                <Barcode value={12345 + i} width={1} margin={0} />
              </div>
            )
          )}
        </div>
      </div>
      <label>width</label>
      <input value={width} onChange={(e) => setWidth(e.target.value)} />
      <label>height</label>
      <input value={height} onChange={(e) => setHeight(e.target.value)} />
      <label>count</label>
      <input value={count} onChange={(e) => setCount(e.target.value)} />

      <ReactToPrint
        content={() => printRef.current}
        trigger={() => <button>print</button>}
        pageStyle={pageStyle}
      />

      <Barcode value={12345} ref={printBasicRef} margin={0} />
      <ReactToPrint
        content={() => printRef.current}
        trigger={() => <button> basic</button>}
        pageStyle={pageStyle}
      />
    </div>
  );
};

function App(props) {
  const printRef = useRef(null);
  const receipt = (
    <Printer type="epson" width={42} characterSet="korea" debug={true}>
      <Text size={{ width: 2, height: 2 }}>9,500원</Text>
      <Text bold={true}>Test</Text>
      <Br />
      <Line />
      <Row left="결제방법" right="체크카드" />
      <Row left="카드번호" right="123456**********" />
      <Row left="할부기간" right="일시불" />
      <Row left="결제금액" right="9,500" />
      <Row left="부가세액" right="863" />
      <Row left="공급가액" right="8,637" />
      <Line />
      <Row left={<Text bold={true}>Test X 2</Text>} right="11,000" />
      <Text> 옵션1(500)/옵션2/"메모"</Text>
      <Row left=" (-) 할인" right="- 500" />
      <Br />
      <Line />
      <Row
        left={<Text bold={true}>합계</Text>}
        right={<Text underline="1dot-thick">9,500</Text>}
      />
      <Row left="(-) 할인 2%" right="- 1,000" />
      <Line />
      <Row left="대표" right="김대표" />
      <Row left="사업자등록번호" right="000-00-00000" />
      <Row left="대표번호" right="0000-0000" />
      <Row left="주소" right="어디시 어디구 어디동 몇동몇호" />
      <Row
        gap={1}
        left={<Text size={{ width: 2, height: 2 }}>포</Text>}
        center={
          <Text size={{ width: 2, height: 2 }}>알로하 포케 맛있는 거</Text>
        }
        right="X 15"
      />
      <Line />
      <Br />
      <Text align="center">Wifi: some-wifi / PW: 123123</Text>
      <Cut />
      <Barcode type="UPC-A" content="111111111111" />
      <Barcode type="CODE39" content="A000$" />
      <Barcode align="center" type="UPC-A" content="111111111111" />
      <QRCode content="https://github.com/seokju-na/react-thermal-printer" />

      {/* <Barcode2 value={12345} margin={0} /> */}
      <Image src="https://my-cdn.com/image.png" />
      <Image align="center" src="https://my-cdn.com/image.png" />
    </Printer>
  );
  const [port, setPort] = useState(2000);
  const [ip, setIp] = useState("");
  const getPrintDeviceList = async () => {
    const nvg = navigator;
    if (nvg && nvg.bluetooth) {
      return await nvg.bluetooth.requestDevice({
        filters: [
          {
            services: ["000018f0-0000-1000-8000-00805f9b34fb"],
          },
        ],
      });
    } else {
      throw new Error("Navigator / Bluetooth is not found!");
    }
  };

  // const handlePrint = async () => {
  //   try {
  //     const deviceList = await getPrintDeviceList();
  //     const gatt = await deviceList?.gatt?.connect();
  //     if (gatt) {
  //       if (typeof gatt.getPrimaryService === "function") {
  //         const service = await gatt.getPrimaryService(
  //           "000018f0-0000-1000-8000-00805f9b34fb"
  //         );
  //         if (service) {
  //           const characteristic = await service.getCharacteristic(
  //             "00002af1-0000-1000-8000-00805f9b34fb"
  //           );
  //           const response = await sendPrintData(characteristic);
  //           console.log("print result:", response);
  //         } else {
  //           console.log("service not found!");
  //         }
  //       } else {
  //         console.log("gatt.getPrimaryService not found!");
  //       }
  //     } else {
  //       console.log("GATT Device not found!");
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  return (
    <>
      <div ref={printRef}>{receipt}</div>
      <ReactToPrint
        content={() => printRef.current}
        trigger={() => <button> basic</button>}
        //  pageStyle={pageStyle}
      />
      <div>
        <button onClick={async () => window.print()}>PRINT test</button>
      </div>
      {/* <label>setIp</label>
      <input value={ip} onChange={(e) => setIp(e.target.value)} />
      <label>setPort</label>
      <input value={port} onChange={(e) => setPort(e.target.value)} />
      <button
        onClick={async () => {
          const data = await render(receipt);
          const conn = connect(
            {
              host: ip + "",
              port: port,
              timeout: 20000,
            },
            () => {
              conn.write(Buffer.from(data), () => {
                conn.destroy();
              });
            }
          );
        }}
      >
        print by port
      </button> */}
      {/* 
      <div>
        <button onClick={async () => await handlePrint()}>PRINT test</button>
      </div> */}
    </>
  );
}

export default App;
